<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("mine.my_questionnaire") }}
      <!-- 我的问卷 -->
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="completes"
        @onChange="switchComplete"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="name"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
            <!-- 请输入名称 -->
          </div>
        </template>
      </ListFilter>
      <!-- 来源 -->
      <ListFilter
        only
        :name="$t('LB_Doc_Source')"
        :dataSource="source"
        @onChange="switchSource"
      />
    </div>
    <div class="list-type-wrap">
      <ListType :checked="listType" @change="(type) => (listType = type)" />
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div
            class="content"
            :class="{ card: listType == 'card', list: listType == 'list' }"
          >
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <ActivityCard
                :newWindow="newWindow == 1"
                :listType="listType"
                :dataSource="item"
              />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { getActivityList } from "@/api/activity.js";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import ListType from "@/components/new/ListType.vue";
import ActivityCard from "@/components/new/mine/ActivityCard.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    ListType,
    ActivityCard,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const state = reactive({
      completes: [
        { name: $t("CM_Completed"), id: 1 }, // 已完成
        { name: $t("CM_NotCompleted"), id: 2 }, // 未完成
        { name: $t("CM_Expired"), id: 3 }, // 已过期
      ], //
      source: [
        { name: $t("activity.investigation"), id: 4 }, // 调查问卷
        { name: $t("activity.evaluate"), id: 7 }, // 评估表
        { name: $t("activity.vote"), id: 11 }, // 投票
      ],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      name: "",
      complete: 0,
      type: 0,
      listType: "list",
      listLoading: true,
    });

    watch(
      () => store.getters.companyInfo,
      (company) => {
        state.listType = company.useUserCenterShow == 2 ? "card" : "list";
      },
      { immediate: true }
    );

    const currentTime = Date.parse(new Date()) / 1000;

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      getActivityList({
        page: state.currentPage,
        pageSize: 15,
        name: state.name,
        complete: state.complete,
        type: state.type,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const switchComplete = (arr) => {
      state.complete = arr[0];
      reloadList();
    };

    const switchSource = (arr) => {
      state.type = arr[0];
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      pageChange,
      switchComplete,
      switchSource,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
